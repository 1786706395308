<template>
  <footer
    ref="parent"
    class="footer"
    itemscope
    itemtype="http://schema.org/WPFooter"
  >
    <div v-if="timeStamp">
      <table>
        <thead>
          <tr>
            <th>url</th>
            <th>TimeStart</th>
            <th>TimeRange</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(timeLine, index) in timeStamp"
            :key="'timeLine' + index"
          >
            <td>{{ timeLine.url }}</td>
            <td>{{ timeLine.timeStart }}</td>
            <td>{{ timeLine.timeDif }}</td>
          </tr>
        </tbody>
      </table>

      <div v-html="JSON.stringify(reqHeaders)" />
    </div>
    <template v-if="needGetCity">
      <ClientOnly>
        <div
          v-show="!siteMapPage"
          class="footer__container container d-flex flex-wrap"
        >
          <div class="footer__container-top d-flex flex-grow">
            <div
              v-if="currentCity?.fias_id"
              class="footer__logo"
            >
              <a
                v-if="$route?.path !== `/`"
                :href="`/`"
                class="logo"
                @click.prevent="moveTo(`/`, 'main_page', '')"
              >
                <img
                  loading="lazy"
                  src="~/assets/images/logo_black.svg"
                  alt="logo"
                >
                Домашний интернет {{ getAddressText.cityOnlyLoc }}
              </a>
              <div
                v-else
                class="logo"
              >
                <img
                  loading="lazy"
                  src="~/assets/images/logo_black.svg"
                  alt="logo"
                >
                ДомИнтернет
              </div>
            </div>
            <div
              v-if="currentCity?.fias_id"
              class="footer__btn-wrap"
            >
              <a
                v-if="
                  $route?.name !== 'personal-address-slug'
                    && $route?.name !== 'city-providers-not-found'
                "
                :href="`/${currentCity.url}/providers-to-address/`"
                class="footer__btn-search title-5 d-flex align-center"
                @click.prevent="
                  $router.push({
                    path: `/${currentCity.url}/providers-to-address/`,
                  })
                "
              >
                <span class="d-flex mask-size-lg mask-search-thin mask-icon" />
                Поиск провайдеров по адресу
                {{
                  currentCity
                    && currentCity.morph_name
                    && currentCity.morph_name["где"]
                    ? currentCity.morph_name["где"]
                    : ""
                }}</a>
            </div>

            <div
              v-if="disabledMobile && currentCity?.fias_id"
              class="footer__btn-wrap d-flex align-start"
            >
              <button
                class="footer__btn-city title-5 d-flex align-center secondary-dark--text"
                @click="showDialogAddress"
              >
                <img
                  loading="lazy"
                  src="@/assets/images/svg/pin-thin_sec.svg"
                  alt="pin"
                >{{ currentCity?.short_name ? currentCity.short_name + " " : ""
                }}{{ currentCity?.name }}
              </button>
            </div>
          </div>
          <div
            class="footer__container-bottom d-flex flex-grow"
            :class="{
              'w-100':
                $route?.name === 'personal-address-slug'
                || $route?.name === 'city-providers-not-found',
            }"
          >
            <div
              ref="targetCall"
              class="footer__callback"
            >
              <template v-if="houseUrl && providers?.length">
                <p class="gray-dark--text title-5">
                  Подключение
                </p>
                <a
                  v-if="!segmentationActive && houseUrl && callNumber && callNumber.call_number"
                  :href="
                    'tel:' + (callNumber.call_number ? callNumber.call_number : '')
                  "
                  class="footer__callback--phone d-block black--text headline-6"
                >
                  {{ callNumber.display_number }}
                </a>
                <a
                  v-else-if="!segmentationActive && callNumberWA"
                  :href="
                    'tel:'
                      + (callNumberWA.call_number ? callNumberWA.call_number : '')
                  "
                  class="footer__callback--phone d-block black--text headline-6"
                >
                  {{ callNumberWA.display_number }}
                </a>
              </template>
            </div>
            <nav
              v-if="
                $route?.name !== 'personal-address-slug'
                  && $route?.name !== 'city-providers-not-found'
              "
              class="footer__nav d-flex"
            >
              <div class="footer__nav--block">
                <div
                  class="footer__nav--item title-5 gray-dark--text d-flex align-center justify-between"
                  @click.stop="showProviders = !showProviders"
                >
                  Провайдеры

                  <img
                    :class="{ rotate: showProviders }"
                    loading="lazy"
                    src="@/assets/images/svg/arrow-expand.svg"
                    alt="arrow"
                  >
                </div>
                <menu
                  itemscope
                  itemtype="http://schema.org/SiteNavigationElement"
                >
                  <template v-if="currentCity?.fias_id && providers">
                    <li
                      v-for="(provider, i) in providers"
                      v-show="isExpandProviders"
                      :key="`provider${i}`"
                      class="footer__links title-5"
                    >
                      <a
                        itemprop="url"
                        :href="`/${currentCity.url}/providers/${provider.provider.slug}/`"
                        target="_blank"
                      >{{ provider.provider.name }}</a>
                    </li>
                  </template>
                  <li
                    v-if="currentCity?.fias_id"
                    v-show="isExpandProviders"
                    class="footer__links title-5"
                  >
                    <a
                      itemprop="url"
                      :href="`/${currentCity.url}/providers/`"
                      @click.prevent="
                        moveTo(
                          `/${currentCity.url}/providers/`,
                          'page_providers_',
                          'all',
                        )
                      "
                    >Все провайдеры
                      {{
                        currentCity
                          && currentCity.morph_name
                          && currentCity.morph_name["Р"]
                          ? currentCity.morph_name["Р"]
                          : ""
                      }}</a>
                  </li>
                </menu>
              </div>
              <div class="footer__nav--block">
                <div
                  class="footer__nav--item title-5 gray-dark--text d-flex align-center justify-between"
                  @click.stop="showTariffLinks = !showTariffLinks"
                >
                  Тарифы
                  <img
                    :class="{ rotate: showTariffLinks }"
                    loading="lazy"
                    src="@/assets/images/svg/arrow-expand.svg"
                    alt="arrow"
                  >
                </div>

                <menu
                  itemscope
                  itemtype="http://schema.org/SiteNavigationElement"
                >
                  <li
                    v-for="(tariff, i) in tariffsLinks"
                    v-show="isExpandTariffs"
                    :key="`tariff${i}`"
                    class="footer__links title-5"
                  >
                    <a
                      itemprop="url"
                      :href="tariff.link"
                      @click.prevent="goToTariffs(tariff)"
                    >{{ tariff.text }}</a>
                  </li>
                  <li
                    v-if="currentCity?.fias_id"
                    v-show="isExpandTariffs"
                    class="footer__links title-5"
                  >
                    <a
                      itemprop="url"
                      :href="`/${currentCity.url}/tariffs/`"
                      @click.prevent="
                        moveTo(
                          `/${currentCity.url}/tariffs/`,
                          'page_tariffs_',
                          'all',
                        )
                      "
                    >Все тарифы
                      {{
                        currentCity
                          && currentCity.morph_name
                          && currentCity.morph_name["Р"]
                          ? currentCity.morph_name["Р"]
                          : ""
                      }}</a>
                  </li>
                </menu>
              </div>
              <div class="footer__nav--block">
                <!--            role="mobile-button" -->
                <div
                  class="footer__nav--item title-5 gray-dark--text d-flex align-center justify-between"
                  @click.stop="showAboutCompany = !showAboutCompany"
                >
                  ДомИнтернет

                  <img
                    :class="{ rotate: showAboutCompany }"
                    loading="lazy"
                    src="@/assets/images/svg/arrow-expand.svg"
                    alt="arrow"
                  >
                </div>

                <menu
                  itemscope
                  itemtype="http://schema.org/SiteNavigationElement"
                >
                  <li
                    v-for="(company, i) in aboutCompanyLinks"
                    v-show="isExpandAboutCompany"
                    :key="`company${i}`"
                    class="footer__links title-5"
                  >
                    <button
                      v-if="company.button"
                      @click="moveTo(company.link, 'page_locations', '')"
                    >
                      {{ company.text }}
                    </button>
                    <a
                      v-else
                      itemprop="url"
                      :href="company.link"
                      @click.prevent="
                        moveTo(`${company.link}`, 'page_locations', '')
                      "
                    >{{ company.text }}</a>
                  </li>
                </menu>
              </div>
              <div class="footer__nav--block">
                <!--            role="mobile-button" -->
                <div
                  class="footer__nav--item title-5 gray-dark--text d-flex align-center justify-between"
                  @click.stop="showSiteMap = !showSiteMap"
                >
                  Карта сайта

                  <img
                    :class="{ rotate: showSiteMap }"
                    loading="lazy"
                    src="@/assets/images/svg/arrow-expand.svg"
                    alt="arrow"
                  >
                </div>

                <menu
                  itemscope
                  itemtype="http://schema.org/SiteNavigationElement"
                >
                  <li
                    v-for="(company, i) in siteMapLinks"
                    v-show="isExpandSiteMap"
                    :key="`company${i}`"
                    class="footer__links title-5"
                  >
                    <a
                      itemprop="url"
                      :href="company.link"
                      @click.prevent="
                        moveTo(`${company.link}`, 'page_locations', '')
                      "
                    >{{ company.text }}</a>
                  </li>
                </menu>
              </div>
            </nav>
            <div
              v-if="!disabledMobile && currentCity?.fias_id"
              class="footer__btn-wrap d-flex align-start"
            >
              <button
                class="footer__btn-city title-5 d-flex align-center secondary-dark--text"
                @click="showDialogAddress"
              >
                <img
                  loading="lazy"
                  src="@/assets/images/svg/pin-thin_sec.svg"
                  alt="pin"
                >{{ currentCity?.short_name ? currentCity.short_name + " " : ""
                }}{{ currentCity?.name }}
              </button>
            </div>
          </div>
        </div>
      </ClientOnly>
    </template>
    <div
      v-else
      v-show="!siteMapPage"
      class="footer__container container d-flex flex-wrap"
    >
      <div class="footer__container-top d-flex flex-grow">
        <div
          v-if="currentCity?.fias_id"
          class="footer__logo"
        >
          <a
            v-if="$route?.path !== `/`"
            :href="`/`"
            class="logo"
            @click.prevent="moveTo(`/`, 'main_page', '')"
          >
            <img
              loading="lazy"
              src="~/assets/images/logo_black.svg"
              alt="logo"
            >
            Домашний интернет {{ getAddressText.cityOnlyLoc }}
          </a>
          <div
            v-else
            class="logo"
          >
            <img
              loading="lazy"
              src="~/assets/images/logo_black.svg"
              alt="logo"
            >
            ДомИнтернет
          </div>
        </div>
        <div
          v-if="currentCity?.fias_id"
          class="footer__btn-wrap"
        >
          <a
            v-if="
              $route?.name !== 'personal-address-slug'
                && $route?.name !== 'city-providers-not-found'
            "
            :href="`/${currentCity.url}/providers-to-address/`"
            class="footer__btn-search title-5 d-flex align-center"
            @click.prevent="
              $router.push({
                path: `/${currentCity.url}/providers-to-address/`,
              })
            "
          >
            <span class="d-flex mask-size-lg mask-search-thin mask-icon" />
            Поиск провайдеров по адресу
            {{
              currentCity
                && currentCity.morph_name
                && currentCity.morph_name["где"]
                ? currentCity.morph_name["где"]
                : ""
            }}</a>
        </div>

        <div
          v-if="disabledMobile && currentCity?.fias_id"
          class="footer__btn-wrap d-flex align-start"
        >
          <button
            class="footer__btn-city title-5 d-flex align-center secondary-dark--text"
            @click="showDialogAddress"
          >
            <img
              loading="lazy"
              src="@/assets/images/svg/pin-thin_sec.svg"
              alt="pin"
            >{{ currentCity?.short_name ? currentCity.short_name + " " : ""
            }}{{ currentCity?.name }}
          </button>
        </div>
      </div>
      <div
        class="footer__container-bottom d-flex flex-grow"
        :class="{
          'w-100':
            $route?.name === 'personal-address-slug'
            || $route?.name === 'city-providers-not-found',
        }"
      >
        <div
          class="footer__callback"
        >
          <template v-if="houseUrl && providers?.length">
            <p class="gray-dark--text title-5">
              Подключение
            </p>
            <a
              v-if="!segmentationActive && houseUrl && callNumber && callNumber.call_number"
              :href="
                'tel:' + (callNumber.call_number ? callNumber.call_number : '')
              "
              class="footer__callback--phone d-block black--text headline-6"
            >
              {{ callNumber.display_number }}
            </a>
            <a
              v-else-if="!segmentationActive && callNumberWA"
              :href="
                'tel:'
                  + (callNumberWA.call_number ? callNumberWA.call_number : '')
              "
              class="footer__callback--phone d-block black--text headline-6"
            >
              {{ callNumberWA.display_number }}
            </a>
          </template>
        </div>
        <nav
          v-if="
            $route?.name !== 'personal-address-slug'
              && $route?.name !== 'city-providers-not-found'
          "
          class="footer__nav d-flex"
        >
          <div class="footer__nav--block">
            <div
              class="footer__nav--item title-5 gray-dark--text d-flex align-center justify-between"
              @click.stop="showProviders = !showProviders"
            >
              Провайдеры

              <img
                :class="{ rotate: showProviders }"
                loading="lazy"
                src="@/assets/images/svg/arrow-expand.svg"
                alt="arrow"
              >
            </div>
            <menu
              itemscope
              itemtype="http://schema.org/SiteNavigationElement"
            >
              <template v-if="currentCity?.fias_id && providers">
                <li
                  v-for="(provider, i) in providers"
                  v-show="isExpandProviders"
                  :key="`provider${i}`"
                  class="footer__links title-5"
                >
                  <component
                    :is="providerBtn ? 'button' : 'a'"
                    itemprop="url"
                    :href="providerBtn ? undefined : `/${currentCity.url}/providers/${provider.provider.slug}/`"
                    target="_blank"
                  >
                    {{ provider.provider.name }}
                  </component>
                </li>
              </template>
              <li
                v-if="currentCity?.fias_id"
                v-show="isExpandProviders"
                class="footer__links title-5"
              >
                <component
                  :is="providersBtn ? 'button' : 'a'"
                  itemprop="url"
                  :href="providersBtn ? undefined : `/${currentCity.url}/providers/`"
                  @click.prevent="
                    moveTo(
                      `/${currentCity.url}/providers/`,
                      'page_providers_',
                      'all',
                    )
                  "
                >
                  Все провайдеры
                  {{
                    currentCity
                      && currentCity.morph_name
                      && currentCity.morph_name["Р"]
                      ? currentCity.morph_name["Р"]
                      : ""
                  }}
                </component>
              </li>
            </menu>
          </div>
          <div class="footer__nav--block">
            <div
              class="footer__nav--item title-5 gray-dark--text d-flex align-center justify-between"
              @click.stop="showTariffLinks = !showTariffLinks"
            >
              Тарифы
              <img
                :class="{ rotate: showTariffLinks }"
                loading="lazy"
                src="@/assets/images/svg/arrow-expand.svg"
                alt="arrow"
              >
            </div>

            <menu
              itemscope
              itemtype="http://schema.org/SiteNavigationElement"
            >
              <li
                v-for="(tariff, i) in tariffsLinks"
                v-show="isExpandTariffs"
                :key="`tariff${i}`"
                class="footer__links title-5"
              >
                <component
                  :is="tariffsBtn ? 'button' : 'a'"
                  itemprop="url"
                  :href="tariffsBtn ? undefined : tariff.link"
                  @click.prevent="goToTariffs(tariff)"
                >
                  {{ tariff.text }}
                </component>
              </li>
              <li
                v-if="currentCity?.fias_id"
                v-show="isExpandTariffs"
                class="footer__links title-5"
              >
                <component
                  :is="tariffsBtn ? 'button' : 'a'"
                  itemprop="url"
                  :href="tariffsBtn ? undefined : `/${currentCity.url}/tariffs/`"
                  @click.prevent="
                    moveTo(
                      `/${currentCity.url}/tariffs/`,
                      'page_tariffs_',
                      'all',
                    )
                  "
                >
                  Все тарифы
                  {{
                    currentCity
                      && currentCity.morph_name
                      && currentCity.morph_name["Р"]
                      ? currentCity.morph_name["Р"]
                      : ""
                  }}
                </component>
              </li>
            </menu>
          </div>
          <div class="footer__nav--block">
            <!--            role="mobile-button" -->
            <div
              class="footer__nav--item title-5 gray-dark--text d-flex align-center justify-between"
              @click.stop="showAboutCompany = !showAboutCompany"
            >
              ДомИнтернет

              <img
                :class="{ rotate: showAboutCompany }"
                loading="lazy"
                src="@/assets/images/svg/arrow-expand.svg"
                alt="arrow"
              >
            </div>

            <menu
              itemscope
              itemtype="http://schema.org/SiteNavigationElement"
            >
              <li
                v-for="(company, i) in aboutCompanyLinks"
                v-show="isExpandAboutCompany"
                :key="`company${i}`"
                class="footer__links title-5"
              >
                <button
                  v-if="company.button"
                  @click="moveTo(company.link, 'page_locations', '')"
                >
                  {{ company.text }}
                </button>
                <a
                  v-else
                  itemprop="url"
                  :href="company.link"
                  @click.prevent="
                    moveTo(`${company.link}`, 'page_locations', '')
                  "
                >{{ company.text }}</a>
              </li>
            </menu>
          </div>
          <div class="footer__nav--block">
            <!--            role="mobile-button" -->
            <div
              class="footer__nav--item title-5 gray-dark--text d-flex align-center justify-between"
              @click.stop="showSiteMap = !showSiteMap"
            >
              Карта сайта

              <img
                :class="{ rotate: showSiteMap }"
                loading="lazy"
                src="@/assets/images/svg/arrow-expand.svg"
                alt="arrow"
              >
            </div>

            <menu
              itemscope
              itemtype="http://schema.org/SiteNavigationElement"
            >
              <li
                v-for="(company, i) in siteMapLinks"
                v-show="isExpandSiteMap"
                :key="`company${i}`"
                class="footer__links title-5"
              >
                <a
                  itemprop="url"
                  :href="company.link"
                  @click.prevent="
                    moveTo(`${company.link}`, 'page_locations', '')
                  "
                >{{ company.text }}</a>
              </li>
            </menu>
          </div>
        </nav>
        <div
          v-if="!disabledMobile && currentCity?.fias_id"
          class="footer__btn-wrap d-flex align-start"
        >
          <button
            class="footer__btn-city title-5 d-flex align-center secondary-dark--text"
            @click="showDialogAddress"
          >
            <img
              loading="lazy"
              src="@/assets/images/svg/pin-thin_sec.svg"
              alt="pin"
            >{{ currentCity?.short_name ? currentCity.short_name + " " : ""
            }}{{ currentCity?.name }}
          </button>
        </div>
      </div>
    </div>

    <div class="footer__sub">
      <div class="container d-flex justify-between">
        <meta
          :content="year"
          itemprop="copyrightYear"
        >
        <meta
          content="ООО «Интернет-Тариф»"
          itemprop="copyrightHolder"
        >
        <p class="subhead-6 footer__sub--year">
          <span class="d-block">&copy;
            {{ year }}
            ООО «Интернет-Тариф»</span>
        </p>

        <p class="subhead-6 gray-dark--text">
          Продолжая использовать наш сайт, вы даете согласие на обработку файлов
          <a
            v-if="$route?.path === '/'"
            class="secondary-dark--text"
            href="/legal/cookies/"
            target="_blank"
          >Cookies</a>
          <button
            v-else
            class="secondary-dark--text"
            @click="goAway('/legal/cookies/')"
          >
            Cookies
          </button>
          и других пользовательских данных, в соответствии с
          <a
            v-if="$route?.path === '/'"
            class="secondary-dark--text"
            href="/legal/privacy/"
            target="_blank"
          >
            Политикой конфиденциальности
          </a>
          <button
            v-else
            class="secondary-dark--text"
            @click="goAway('/legal/privacy/')"
          >
            Политикой конфиденциальности
          </button>
          и
          <a
            v-if="$route?.path === '/'"
            class="secondary-dark--text"
            href="/legal/terms-of-use/"
            target="_blank"
          >
            Пользовательским соглашением
          </a>
          <button
            v-else
            class="secondary-dark--text"
            @click="goAway('/legal/terms-of-use/')"
          >
            Пользовательским соглашением
          </button>
        </p>

        <div class="d-flex align-start">
          <button
            class="footer__soc"
            @click="goAway('/away/?to=https://vk.com/dominternet_ru')"
          >
            <img
              loading="lazy"
              src="@/assets/images/svg/vk.svg"
              alt="vk"
              width="32"
              height="32"
            >
          </button>
          <button
            class="footer__soc"
            @click="goAway('/away/?to=https://dzen.ru/dominternet')"
          >
            <img
              loading="lazy"
              src="@/assets/images/svg/zen.svg"
              alt="zen"
              width="32"
              height="32"
            >
          </button>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useCities } from '~/store/cites/cities.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { useMainStore } from '~/store/main/main.store'
import { timeArray } from '~~/api/openapi/common'
import { getCallNumber } from '~/composible/getCallNumber'
import { goAway } from '~/helpers'
import { providersComposible } from '~/composible/getProviders'

const year = new Date().getFullYear()
const ctx = useNuxtApp()
const $route = useRoute()
const $router = useRouter()
const cityStore = useCities()
const tariffsStore = useTariffs()
const mainStore = useMainStore()
const disabledMobile = computed(() => mainStore.disabledMobile)
const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
const currentCity = computed(() => cityStore.getCity)
const isDesktop = computed(() => ctx.$device.isDesktop)
const needGetCity = computed(() => cityStore.getNeedGetCity)
const houseUrl = computed(() => tariffsStore.getHouseUrl)
const getAddressText = computed(() => tariffsStore.getAddressText)
const segmentationActive = computed(() => mainStore.getSegmentationActive)
const reqHeaders = computed(() => mainStore.getReqHeaders)
const showTariffLinks = ref(false)
const showAboutCompany = ref(false)
const showSiteMap = ref(false)
const showProviders = ref(false)
const isExpandTariffs = computed(
  () => isDesktop.value || showTariffLinks.value,
)
const isExpandAboutCompany = computed(
  () => isDesktop.value || showAboutCompany.value,
)
const isExpandSiteMap = computed(() => isDesktop.value || showSiteMap.value)
const isExpandProviders = computed(
  () => isDesktop.value || showProviders.value,
)
const { callNumber, getPhoneNumber, stopGetCall } = getCallNumber()
const callNumberWA = computed(() => mainStore.getCallNumber?.connect)
const parent = ref()
const siteMapPage = computed(
  () => $route?.name === 'locations' || $route?.name === 'providers',
)
const indexTopologies = computed(() => cityStore.getIndexTopologies)
const providerBtn = computed(() => !indexTopologies.value.includes('/{city}/providers/{provider}/'))
const providersBtn = computed(() => !indexTopologies.value.includes('/{city}/providers/'))
const tariffsBtn = computed(() => !indexTopologies.value.includes('/{city}/tariffs/'))
const defaultBtns = ref([
  {
    smart_filter: {
      slug: 'internet',
      name: 'Только интернет',
      link: '/msk/tariffs/internet/',
    },
  },
  {
    smart_filter: {
      slug: 'internet-tv',
      name: 'Интернет + ТВ',
      link: '/msk/tariffs/internet-tv/',
    },
  },
  {
    smart_filter: {
      slug: 'internet-mobile',
      name: 'Интернет + Моб. связь',
      link: '/msk/tariffs/internet-mobile/',
    },
  },
  {
    smart_filter: {
      slug: 'internet-tv-mobile',
      name: 'Интернет + ТВ + Моб. связь',
      link: '/msk/tariffs/internet-tv-mobile/',
    },
  },
  {
    smart_filter: { slug: 'tv', name: 'Только ТВ', link: '/msk/tariffs/tv/' },
  },
])
const showDialogAddress = () => {
  ctx.$event('addressDialog', { redirect: true, label: 'footer' })
}
const servicesFilters = computed(() =>
  tariffsStore.getSmartFilters.length
    ? tariffsStore.getSmartFilters.filter(
      (el: any) =>
        el && el.smart_filter && el.smart_filter.category === 'SERVICE',
    )
    : defaultBtns.value,
)

const aboutCompanyLinks = computed(() => [
  {
    text: 'Блог',
    link: '/blog/',
  },
  {
    text: 'Проверка скорости',
    link: '/speedtest/',
  },
  {
    text: 'Детектор сбоев',
    link: '/downdetector/',
  },
  {
    text: 'О проекте',
    link: '/about/',
    button: $route?.path !== '/',
  },
  {
    text: 'Вакансии',
    link: '/career/',
    button: $route?.path !== '/',
  },
  {
    text: 'Контакты',
    link: '/contacts/',
    button: $route?.path !== '/',
  },
  // {
  //   text: 'Помощь',
  //   link: '/help/'
  // }
])
const siteMapLinks = computed(() => [
  {
    text: 'Города',
    link: '/locations/',
  },
  {
    text: `Улицы ${currentCity.value?.morph_name?.['Р'] || currentCity.value?.name}`,
    link: `/${currentCity.value?.url}/streets/`,
  },
  // {
  //   text: 'Регионы',
  //   link: '/locations/'
  // },
  {
    text: 'Поиск по адресу',
    link: '/providers-to-address/',
  },
  {
    text: 'Провайдеры',
    link: '/providers/',
  },
])

const tariffsLinks = computed(
  () =>
    servicesFilters.value?.map((el: any) => {
      return {
        slug: el.smart_filter.slug,
        text: el.smart_filter.name,
        link: `/${currentCity.value.url}/tariffs/${el.smart_filter.slug}/`,
      }
    }),
)
const { fetchProvidersList } = providersComposible()

const moveTo = (url: string) => {
  if ($route?.path === url) window.scrollTo({ top: 0 })
  $router.push(url)
}

const goToTariffs = (tariff: any) => {
  if (
    $route?.name?.toString().startsWith('city-tariffs')
    && !$route?.path.includes('providers/')
  )
    window.scrollTo({ top: 0 })
  $router.push(tariff.link)
}

const timeStamp = computed(() => data.value?.timeStamp)
const providers = computed(() => data.value?.providers)

let errorStatus
let errorMessage
const { data, error, refresh } = await useAsyncData('footer' + (currentCity.value?.fias_id || '') + (houseUrl.value || ''), async () => {
  let timeStamp
  let providers

  const timeStampFetch = async () => {
    if ($route?.query.debug === '1') {
      timeStamp = timeArray
    }
    else timeStamp = undefined
  }
  const providersFetch = async () => {
    if (currentCity.value?.fias_id) providers = await fetchProvidersList({}, { limit: 5, offset: 0 })
    else providers = null
  }

  await Promise.all([timeStampFetch(), providersFetch()])

  return {
    timeStamp,
    providers,
  }
})

if (error.value) {
  throw createError({
    statusCode: errorStatus,
    fatal: true,
    message: errorMessage,
  })
}

watch(
  () => currentCity.value?.fias_id,
  async () => {
    data.value.providers = await fetchProvidersList({}, { limit: 5, offset: 0 })
  },
)
watch(
  () => houseUrl.value,
  async () => {
    data.value.providers = await fetchProvidersList({}, { limit: 5, offset: 0 })
  },
)
const options = {
  rootMargin: '0px',
  threshold: 0.1,
}
const checkPosition = (entries) => {
  if (entries && entries[0].isIntersecting) {
    if (houseUrl.value && providers.value && providers.value.length)
      getPhoneNumber(false, undefined, true)
  }
  else stopGetCall()
}

onBeforeMount(async () => {
  if (data.value?.providers === null)
    data.value.providers = await fetchProvidersList({}, { limit: 5, offset: 0 })
})

onMounted(() => {
  if ($route?.query.debug === '1') {
    timeStamp.value?.push({
      url: 'Mouted',
      timeStart: new Date() - timeStamp.value?.[0]?.timeStart,
      timeDif: 0,
    })
  }
  const observer = new IntersectionObserver(checkPosition, options)
  observer.observe(parent.value)
})
</script>

<style scoped lang="scss">
.footer {
  margin-top: auto;
  width: 100vw;
  background: color(gray-light-2);

  .mask-search-thin {
    mask-image: url('@/assets/images/svg/search-thin.svg');
  }

  &__container {
    padding-top: 48px;
    padding-bottom: 40px;

    @media (max-width: getBreakpoint(tablet)) {
      flex-wrap: wrap;
      flex-direction: column;
      padding-top: 24px;
      padding-bottom: 24px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      padding-bottom: 20px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      padding-bottom: 16px;
    }

    &-top,
    &-bottom {
      width: 100%;
      @media (max-width: getBreakpoint(tablet)) {
        width: unset;
        flex-direction: column;
      }
    }
  }

  &__logo {
    flex: 0 0 267px;
    display: flex;
    margin-bottom: 64px;

    .logo img {
      width: 159px;
      height: 46px;
      @media (max-width: getBreakpoint(tablet)) {
        width: 153px;
        height: 38px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        width: 146px;
        height: 36px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        width: 130px;
        height: 32px;
      }
    }
    @media (max-width: getBreakpoint(tablet)) {
      flex: unset;
      margin-bottom: 32px;
    }
  }

  &__btn {
    &-wrap {
      flex: 0 0 calc((100% - 268px) / 2);
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 40px;
        height: 1px;
        background-color: color(gray-darker);
      }

      @media (max-width: getBreakpoint(tablet)) {
        flex: unset;
        margin-bottom: 24px;
        &.d-flex {
          order: 3;
          margin-bottom: 0;
        }
        &:before {
          content: none;
        }
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        margin-bottom: 20px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        margin-bottom: 16px;
      }
    }

    &-search {
      transition: color 0.24s;
      color: color(black);
      .mask-icon {
        margin-right: 4px;
        @media (max-width: getBreakpoint(mobile-md)) {
          width: 20px;
          height: 20px;
          mask-size: 20px;
        }
      }
      &:hover {
        color: color(primary);
      }
    }

    &-city {
      transition: color 0.24s;
      margin-left: auto;
      img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }
      &:hover {
        color: color(primary);
      }
      @media (max-width: getBreakpoint(tablet)) {
        margin-left: unset;
      }
    }
  }

  &__nav {
    flex: 1;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    flex-wrap: wrap;
    min-width: 60%;

    @media (max-width: getBreakpoint(tablet)) {
      flex-wrap: nowrap;
      flex-direction: column;
      margin-bottom: 0;
      padding-top: 24px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: -16px;
        right: -16px;
        top: 0;
        height: 1px;
        background-color: color(gray-darker);
      }
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      padding-top: 20px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      padding-top: 16px;
    }

    &--block {
      //color: color(white);
      flex: 0 0 calc(25% - 24px);

      @media (max-width: getBreakpoint(tablet)) {
        margin-bottom: 10px;
        flex: 100%;
        width: 100%;
      }

      @media screen and (min-width: getBreakpoint(desktop)) {
      }
    }

    &--item {
      position: relative;
      margin-bottom: 12px;
      img {
        display: none;
        transition: transform 0.24s;
        width: 24px;
        height: 24px;
      }

      @media (max-width: getBreakpoint(tablet)) {
        cursor: pointer;
        color: color(black);
        font-weight: 500;
        img {
          display: flex;
        }
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        margin-bottom: 8px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        margin-bottom: 6px;
      }
    }
  }

  &__callback {
    flex: 0 0 267px;

    @media (max-width: getBreakpoint(tablet)) {
      margin-left: 0;
      order: 2;
      margin-top: 16px;
      flex: unset;
    }

    &--phone {
      cursor: pointer;
      margin-bottom: 24px;
      transition: color 0.24s;
      margin-top: 4px;
      &:hover {
        color: color(primary);
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        margin-bottom: 20px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        margin-bottom: 16px;
      }
    }
  }

  &__links {
    display: block;
    margin-bottom: 12px;

    a,
    button {
      color: color(black);
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
        color: color(primary);
      }
    }
  }
  &__city {
    margin-top: 68px;
    position: relative;
    padding-left: 267px;
    &:before {
      content: "";
      position: absolute;
      left: min(calc((100vw - 1220px) / -2), -16px);
      right: min(calc((100vw - 1220px) / -2), -16px);
      top: -40px;
      height: 1px;
      background-color: color(gray-darker);
    }
    .footer__links {
      display: inline;
    }
    @media (max-width: getBreakpoint(tablet)) {
      margin-top: 0;
      padding-left: 0;
      padding-bottom: 12px;
      &:before {
        top: unset;
        bottom: 0;
      }
    }
  }

  &__sub {
    width: inherit;
    color: color(gray-dark);
    padding: 40px 0;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: min(calc((100vw - 1220px) / -2), -16px);
      right: min(calc((100vw - 1220px) / -2), -16px);
      top: 0;
      height: 1px;
      background-color: color(gray-darker);
    }

    &--year {
      flex: 0 0 267px;
    }

    @media (max-width: getBreakpoint(tablet)) {
      padding: 24px 0 80px;
      & > .d-flex {
        flex-direction: column;
      }
      &--year {
        flex: unset;
        margin-bottom: 8px;
      }
      .small:first-child {
        margin-bottom: 8px;
      }
    }
  }

  &__soc {
    margin-right: 12px;
    font-size: 0;
    background-color: color(white);
    border-radius: 50%;
    transition: background-color 0.3s;
    width: 32px;
    img {
      object-fit: contain;
      object-position: center;
    }

    &:hover {
      background-color: color(gray-lighter);
    }
    @media (max-width: getBreakpoint(tablet)) {
      margin-top: 24px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-top: 20px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      margin-top: 16px;
    }
  }
}
</style>
